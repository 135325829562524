import React from 'react';
import './modal.css';
import { modalProps } from '../../types';

// TODO confirm closure if data changed
// TODO close window on gray area click if not data is changed

const Modal = ({ title, active, setActive, children }: modalProps) => {
    // Prevent body from scrolling when modal is open
    active ? document.body.classList.add('noscroll') : document.body.classList.remove('noscroll');
    return (
        <div className={active ? 'active modal' : 'modal'}>
            <div
                className={active ? 'active modalContent' : 'modalContent'}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modalTitle">
                    <h2>{title}</h2>
                    <div className="close" onClick={() => setActive(false)}>
                        &times;
                    </div>
                </div>
                <div className="modalBody">{children}</div>
            </div>
        </div>
    );
};

export default Modal;
