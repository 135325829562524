import { DateBookingItem } from '../../types';

const bookingShiftValues = ['', ' [УТРО]', ' [ВЕЧЕР]'];

export const showDateBookingList = (
    dateBookingList: DateBookingItem[],
    bookingDateString: string,
    selectedUserName = ''
) => {
    let result = <div id="dateBookingList"></div>;
    // TODO: написать нормальный текст, закрыть переводом
    if (dateBookingList.length) {
        dateBookingList
            .sort((a, b) => a.area.localeCompare(b.area))
            .sort((a, b) => Number(a.desk) - Number(b.desk));
        result = (
            <div id="dateBookingList">
                <div>
                    Бронирования на <b>{bookingDateString}</b> для доступных зон
                </div>
                <ul>
                    {dateBookingList.map((item: DateBookingItem) => (
                        <li
                            key={`dateBookingListItem-${item.desk}-${item.bookingShift}`}
                            className={item.userName == selectedUserName ? 'bold' : ''}
                        >
                            {`${item.area}, стол ${item.desk}: ${item.userName}`}
                            {bookingShiftValues[item.bookingShift || 0]}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
    return result;
};
