import { anyObject, tableField } from '../types';

const fieldHeaders = (tableName: string, fields: tableField[]) => (
    <tr key={`${tableName}-header`}>
        {fields.map((item: tableField) => (
            <th key={`${tableName}-${item.name}`}>{item.name}</th>
        ))}
    </tr>
);

const fieldValues = (tableName: string, items: anyObject[], fields: tableField[]) => {
    let i = 0;
    return items.map((item: anyObject) => (
        <tr key={`${tableName}-${i++}`}>
            {fields.map((field) => {
                let value = item[field.name] ? item[field.name] : '';
                value = field.valueProcessor ? field.valueProcessor(value) : value;
                return (
                    <td
                        key={`${tableName}-${i++}-${field.name}`}
                        className={item['deletedAt'] ? 'graycell' : ''}
                    >{`${value}`}</td>
                );
            })}
        </tr>
    ));
};

export const tablePrinter = (tableName: string, items: anyObject[], fields: tableField[]) => (
    <table>
        <thead>{fieldHeaders(tableName, fields)}</thead>
        <tbody>{fieldValues(tableName, items, fields)}</tbody>
    </table>
);
