import { useState, useEffect } from 'react';
import { API_ADMIN_USER_LIST } from '../../config';
import { logError } from '../../helpers/logger';
import { tablePrinter } from '../../helpers/tablePrinter';
import { anyObject, tableField } from '../../types';

export const UserList = () => {
    const [state, setState] = useState({
        userList: []
    });

    useEffect(() => {
        getUserList();
    }, []);

    const getUserList = () => {
        fetch(API_ADMIN_USER_LIST)
            .then((res: Response) => res.json())
            .then((json) => json.data)
            .then((userList) => {
                setState({ ...state, userList });
            })
            .catch((err) => logError(`Can not get user list: ${err}`));
    };

    const areasProcessor = (field: anyObject[]) =>
        field.reduce(
            (acc: string, currentValue: anyObject) =>
                acc ? `${acc}; ${currentValue.name}` : `${currentValue.name}`,
            ''
        );

    const datetime2dateProcessor = (field: string) => field.split('T')[0];

    const fields: tableField[] = [
        { name: 'id' },
        { name: 'login' },
        { name: 'name' },
        { name: 'fullName' },
        { name: 'Areas', valueProcessor: areasProcessor },
        { name: 'activeFrom', valueProcessor: datetime2dateProcessor },
        { name: 'activeTill', valueProcessor: datetime2dateProcessor },
        { name: 'deletedAt', valueProcessor: datetime2dateProcessor }
    ];

    return tablePrinter('userList', state.userList, fields);
};
