import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header';
import Main from './components/main';
import { Fragment } from 'react';
import Admin from './components/admin';

const App = () => (
    <div>
        <Router>
            <Fragment>
                <Header />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/admin/" element={<Admin />} />
                </Routes>
            </Fragment>
        </Router>
    </div>
);

export default App;
