/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { DEBUG } from '../config';

export const log = (...args: any[]) => {
    if (DEBUG) {
        const stack = new Error().stack;
        const caller = stack?.split('\n')[2].trim();
        console.log(`LOG in function ${caller}: \n`, ...args);
    }
};

export const logError = (...args: any[]) => {
    if (DEBUG) {
        const stack = new Error().stack;
        const caller = stack?.split('\n')[2].trim();
        console.error(`ERROR in function ${caller}: \n`, ...args);
    }
};
