import moment from 'moment';

export const fetchOptions: object = (body: unknown, method: string) => {
    return {
        body,
        method,
        headers: { 'Content-type': 'text/plain' }
    };
};

export const today: Date = new Date(new Date().toDateString());
export const tomorrow = (fromDate = today) => moment(fromDate).add(1, 'days').toDate();
export const yesterday = (fromDate = today) => moment(fromDate).subtract(1, 'days').toDate();
export const dateToString = (date: Date) => moment(date).format('YYYY-MM-DD');

export const encodeQueryData = (data: object) => {
    const ret = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
};

export const showAlert = (msg: string, duration = 1000) => {
    if (document.getElementById('notification')) return;
    const el = document.createElement('div');
    el.id = 'notification';
    el.classList.add('notification');
    el.innerHTML = msg;
    setTimeout(() => el.classList.toggle('transparent'), duration);
    setTimeout(() => el.parentNode?.removeChild(el), duration + 2000);
    document.body.appendChild(el);
};
