// messageType can be of any CSS class defined, i.e. attention (red), allgood (green), greynote (grey)

import { UnderFormMessageType } from '../types';

export const showMessageUnderTheForm = (
    text: string,
    messageType: UnderFormMessageType = 'rednote'
) => {
    return (
        <p>
            <span className={messageType}>{text}</span>
        </p>
    );
};
