import { useState } from 'react';
import { UserForm } from './admin/userForm';
import { UserList } from './admin/userList';
import Modal from './modal/modal';
import '../css/styles.css';

const Admin = () => {
    const [addUserModalActive, setAddUserModalActive] = useState(false);

    // TODO FIX showing allowed areas for users in the list

    const UserAddButton = () => (
        <div className="addButton">
            <input
                type="button"
                value="Добавить"
                accessKey="a"
                onClick={() => setAddUserModalActive(true)}
            />
        </div>
    );

    return (
        <div>
            <h2>Admin Area</h2>
            <div className="adminContent">
                <UserAddButton />
                <UserList />
                <UserAddButton />
                <Modal
                    title="Добавление пользователя"
                    active={addUserModalActive}
                    setActive={setAddUserModalActive}
                >
                    <UserForm setActive={setAddUserModalActive} />
                </Modal>
            </div>
        </div>
    );
};

export default Admin;
