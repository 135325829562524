import { dateToString } from '../../helpers/common';
import { AreaDetails, BookingListItem } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showOfficePicture = (state: any) => {
    let areaPicturePath = '';
    if (state.availableDesks.length) {
        const selectedItem: AreaDetails | undefined = state.availableDesks.find(
            (item: AreaDetails) => item.id == state.areaId
        );
        if (selectedItem?.scheme) areaPicturePath = selectedItem.scheme;
    }
    if (state.userBookingList.length) {
        const selectedBookingItem = state.userBookingList.find(
            (item: BookingListItem) => dateToString(item.bookingDate) == state.bookingDateString
        );
        if (selectedBookingItem?.areaScheme) areaPicturePath = selectedBookingItem.areaScheme;
    }
    return <div>{areaPicturePath && <img src={`/images/${areaPicturePath}`} />}</div>;
};
