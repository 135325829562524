const Header = () => {
    return (
        <div>
            <h1>
                <b>
                    <a id="goToRoot" href="/" className="header_link">
                        Onboard
                    </a>
                </b>{' '}
                | Бронирование места
            </h1>
        </div>
    );
};
export default Header;
