import moment from 'moment';
import { useEffect, useState } from 'react';
import { logError } from '../../helpers/logger';
import { dateToString } from '../../helpers/common';
import { API_ADMIN_USER_LIST, API_AREA_LIST } from '../../config';
import { modalProps } from '../../types';
import { showMessageUnderTheForm } from '../../helpers/forms';

// TODO implement axios and separate class for API
// TODO check that at least 1 zone is added
// TODO FIX deleting last char from the input - now it doesn't work
// TODO move form to popup
// TODO Replace separate form fields with form generator
// TODO add errors processing and adding to page
// TODO add fields validation both on frontend and backend
// TODO FIX check why both GETs (user and area) are run twice from the frontend

const emptyStringArray: string[] = [];

const defaultState = {
    userId: 0,
    login: '',
    name: '',
    fullName: '',
    areas: [{ id: 1, name: '' }],
    assignedAreas: emptyStringArray,
    activeFrom: moment().format('YYYY-MM-DD'),
    activeTill: '',
    message: '',
    error: ''
};

const emptyMessage = {
    message: '',
    error: ''
};

export const UserForm = ({ setActive }: modalProps) => {
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        getAreaList();
    }, []);

    const getAreaList = () => {
        fetch(API_AREA_LIST)
            .then((res: Response) => res.json())
            .then((json) => json.data)
            .then((areas) => {
                setState({ ...state, areas });
            })
            .catch((err) => logError(`Can not get area list: ${err}`));
    };

    const onGenericInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target?.name || '';
        const value = event.target?.value || '';
        key && setState({ ...state, [key]: value });
    };

    const onZonesAvaiableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const zonesSelect = event.target || '';
        const values = [];
        if (zonesSelect.multiple) {
            for (let i = 0; i < zonesSelect.options.length; i++) {
                if (zonesSelect.options[i].selected) values.push(zonesSelect.options[i].value);
            }
        } else values.push(zonesSelect.value);
        setState({ ...state, assignedAreas: values });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onUserSubmit = (event: any) => {
        event.preventDefault();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newItem: any = {
            login: event.target.login.value || '',
            activeFrom: dateToString(event.target.activeFrom.value) || moment().format('YYYY-MM-DD')
        };
        if (event.target.name.value) newItem.name = event.target.name.value;
        if (event.target.fullName.value) newItem.fullName = event.target.fullName.value;
        if (event.target.activeTill.value)
            newItem.activeTill = dateToString(event.target.activeTill.value);
        const reqBody = {
            user: newItem,
            areas: state.assignedAreas.length ? state.assignedAreas.map((item) => Number(item)) : []
        };

        //TODO: Replace with proper checks for each value and messages
        if (reqBody.user?.login && reqBody.user?.name && reqBody.areas.length) {
            fetch(`${API_ADMIN_USER_LIST}`, {
                body: JSON.stringify(reqBody),
                method: 'POST',
                headers: { 'Content-type': 'application/json' }
            })
                .then(async (res) => {
                    const msg = emptyMessage;
                    const result = await res.json();
                    if (res.status === 201) msg.message = result.message;
                    else msg.error = result.message;
                    // return msg;
                    // TODO Refresh table after insert and add popup on top saying of success or error message under the form
                    setActive(false);
                    setState({
                        ...defaultState,
                        areas: state.areas,
                        error: msg.error,
                        message: msg.message
                    });
                })
                // .then((msg) => getDeskAndBookingList(userId, bookingDateString, msg))
                .catch((err) => {
                    logError(err);
                    setState({
                        ...state,
                        ...newItem,
                        error: err.message,
                        message: ''
                    });
                });
        } else {
            const errorMessage = 'Something went wrong - user login, name or areas are not defined';
            logError(errorMessage);
            setState({
                ...state,
                ...newItem,
                error: errorMessage
            });
        }
    };

    return (
        <div className="UserForm">
            <form id="userForm" onSubmit={onUserSubmit}>
                <label htmlFor="login">
                    Логин<sup className="rednote">*</sup>
                </label>
                <br />
                <input
                    value={state.login}
                    autoComplete="off"
                    type="text"
                    name="login"
                    id="login"
                    placeholder="Логин, как в домене"
                    onChange={onGenericInputChange}
                />
                <br />
                <label htmlFor="name">
                    Краткое имя<sup className="rednote">*</sup>
                </label>
                <br />
                <input
                    value={state.name}
                    autoComplete="off"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Краткое имя для выпадашек"
                    onChange={onGenericInputChange}
                />
                <br />
                <label htmlFor="fullName">Полное имя</label>
                <br />
                <input
                    value={state.fullName}
                    autoComplete="off"
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Полное имя для идентификации при совпадающих инициалах"
                    onChange={onGenericInputChange}
                />
                <br />
                <label htmlFor="assignedAreas">
                    Доступные зоны<sup className="rednote">*</sup>
                </label>
                <br />
                <select
                    name="assignedAreas"
                    id="assignedAreas"
                    onChange={onZonesAvaiableChange}
                    value={state.assignedAreas}
                    multiple
                >
                    {state.areas.map((item) => (
                        <option value={item.id} key={`areasKey-${item.id}`}>
                            {item.name}
                        </option>
                    ))}
                </select>
                <br />
                <label htmlFor="activeFrom">Дата начала </label>
                <br />
                <input
                    value={state.activeFrom}
                    type="date"
                    name="activeFrom"
                    id="activeFrom"
                    onChange={onGenericInputChange}
                />
                <br />
                <label htmlFor="activeTill">Дата завершения </label>
                <br />
                <input
                    value={state.activeTill}
                    type="date"
                    name="activeTill"
                    id="activeTill"
                    onChange={onGenericInputChange}
                />
                <br />
                <br />
                <input type="submit" value="Добавить" />
            </form>
            {state.error !== '' && showMessageUnderTheForm(state.error)}
        </div>
    );
};
